<template>
   <!--<input type="text" class="form-control nombre_correo" v-model="remitente_predeterminado" placeholder="Nombre Correo">-->
    <div class="row">
        <div class="col-md-12">
            <seccion-datos :titulo="cuentasemail" @cambios="guardar(usuario.id)">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>
                                    Usuario
                                </th>
                                <th>
                                    Dirección
                                </th>
                                <th>
                                    Clave
                                </th>
                                <th>
                                    Servidor
                                </th>
                                <th>
                                    Servidor SMTP
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <input type="text" v-model="usuario_mail_usuario" class="form-control nombre_completo dato"
                                    id="nombre_completo">
                                </td>
                                <td>
                                    <input type="mail" v-model="direccion_mail_usuario" class="form-control nombre_completo dato" id="nombre_completo">
                                </td>
                                <td>
                                    <input type="password" v-model="clave_mail_usuario" class="form-control nombre_completo dato" id="nombre_completo">
                                </td>
                                <td>
                                    <input type="text" v-model="server_mail_usuario" class="form-control nombre_completo dato" id="nombre_completo">
                                </td>
                                <td>
                                    <input type="mail" v-model="servidor_smtp" class="form-control nombre_completo dato" id="nombre_completo">
                                </td>
                            </tr>
                        </tbody>
                    </table>
            </seccion-datos>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <seccion-datos :titulo="'Correos adicionales'" :botonGuardar=false>
                <div class="callout">
                    <div style="text-align: center;">
                        <h3 style="font-weight: bold;">Nuevo correo</h3>
                    </div>
                    <table class="table">
                        <thead>
                            <tr>
                                <th>
                                    Nombre
                                </th>
                                <th>
                                    Usuario
                                </th>
                                <th>
                                    Dirección
                                </th>
                                <th>
                                    Clave
                                </th>
                                <th>
                                    Servidor
                                </th>
                                <th>
                                    Servidor SMTP
                                </th>
                                <th>
                                    Puerto SMTP
                                </th>
                                <th>

                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <input type="text" v-model="nombre_nuevo_mail" class="form-control nombre_completo dato"
                                    id="nombre_completo">
                                </td>
                                <td>
                                    <input type="text" v-model="usuario_nuevo_mail" class="form-control nombre_completo dato"
                                    id="nombre_completo">
                                </td>
                                <td>
                                    <input type="mail" v-model="direccion_nuevo_mail" class="form-control nombre_completo dato" id="nombre_completo">
                                </td>
                                <td>
                                    <input type="password" v-model="clave_nuevo_mail" class="form-control nombre_completo dato" id="nombre_completo">
                                </td>
                                <td>
                                    <input type="text" v-model="servidor_nuevo_mail" class="form-control nombre_completo dato" id="nombre_completo">
                                </td>
                                <td>
                                    <input type="mail" v-model="servidor_smtp_nuevo_mail" class="form-control nombre_completo dato" id="nombre_completo">
                                </td>
                                <td>
                                    <input type="text" v-model="puerto_nuevo_mail" class="form-control nombre_completo dato" id="nombre_completo">
                                </td>
                                <td>
                                    <button class="btn btn-primary btn-sm" @click="anadirCorreo">Añadir</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="mt-3">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>
                                    Nombre
                                </th>
                                <th>
                                    Usuario
                                </th>
                                <th>
                                    Dirección
                                </th>
                                <th>
                                    Clave
                                </th>
                                <th>
                                    Servidor
                                </th>
                                <th>
                                    Servidor SMTP
                                </th>
                                <th>

                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr  v-for="correo in correos" :key="correo.id">
                                <td>
                                    <input type="text" v-model="correo.nombre" class="form-control nombre_completo dato"
                                    id="nombre_completo">
                                </td>
                                <td>
                                    <input type="text" v-model="correo.usuario" class="form-control nombre_completo dato"
                                    id="nombre_completo">
                                </td>
                                <td>
                                    <input type="mail" v-model="correo.direccion" class="form-control nombre_completo dato" id="nombre_completo">
                                </td>
                                <td>
                                    <input type="password" v-model="correo.clave" class="form-control nombre_completo dato" id="nombre_completo">
                                </td>
                                <td>
                                    <input type="text" v-model="correo.servidor" class="form-control nombre_completo dato" id="nombre_completo">
                                </td>
                                <td>
                                    <input type="mail" v-model="correo.servidor_smtp" class="form-control nombre_completo dato" id="nombre_completo">
                                </td>
                                <td style="display: flex; gap: 5px">
                                    <button class="btn btn-sm btn-success" @click="editarCorreo(correo)">Editar</button>
                                    <button class="btn btn-sm btn-danger" @click="eliminarCorreo(correo)">Eliminar</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </seccion-datos>
        </div>
    </div>
</template>
<script>
import { PwgsApi } from '../../../../services/PwgsApi';
export default {
    props: ['usuario'],
    data() {
        return {
            usuario_mail_usuario: '',
            clave_mail_usuario: '',
            server_mail_usuario: '',
            direccion_mail_usuario: '',
            remitente_predeterminado: '',
            id: '',
            servidor_smtp:'',
            cuentasemail: "",
            nombre_nuevo_mail: '',
            usuario_nuevo_mail: '',
            clave_nuevo_mail: '',
            direccion_nuevo_mail: '',
            servidor_smtp_nuevo_mail: '',
            puerto_nuevo_mail: '',
            servidor_nuevo_mail:'',
            correos:[],
        }
    },
    methods: {
        async eliminarCorreo(correo){
            const api = new PwgsApi();
            const resp = await api.delete('usuarios/'+this.usuario.id+'/correos/'+correo.id);
            if(resp == 'OK'){
                this.cargarCorreos();
            }else{
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Error al eliminar el correo', life: 2000 });
            }
        },
        async editarCorreo(correo){
            const api = new PwgsApi();
            const resp = await api.put('usuarios/'+this.usuario.id+'/correos/'+correo.id, {nombre:correo.nombre, usuario: correo.usuario, 
                direccion:correo.direccion, clave:correo.clave, servidor:correo.servidor, servidor_smtp: correo.servidor_smtp});
            console.log('respuesta',resp);
            if(resp=='OK'){
                this.$toast.add({ severity:'success', summary: 'Correo editado', detail: 'Correo editado correctamente', life: 2000 });
            }else{
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Error al editar el correo', life: 2000 });
            }
        },
        async anadirCorreo(){
            const api = new PwgsApi();
            await api.post('/usuarios/'+this.usuario.id+'/correos',{nombre: this.nombre_nuevo_mail, usuario:this.usuario_nuevo_mail, 
                direccion: this.direccion_nuevo_mail, clave: this.clave_nuevo_mail, servidor:this.servidor_nuevo_mail,
                servidor_smtp: this.servidor_smtp_nuevo_mail, puerto_smtp: this.puerto_nuevo_mail});
            this.cargarCorreos();
        },
        cargardatos() {
            this.usuario_mail_usuario = this.usuario.usuario_mail_usuario;
            this.clave_mail_usuario = this.usuario.clave_mail_usuario;
            this.server_mail_usuario = this.usuario.server_mail_usuario;
            this.direccion_mail_usuario = this.usuario.direccion_mail_usuario;
            this.remitente_predeterminado = this.usuario.remitente_predeterminado;
            this.servidor_smtp = this.usuario.servidor_smtp;
            this.cuentasemail = 'Mi correo';
        },
        async cargarCorreos(){
            const api = new PwgsApi();
            const correos = await api.get('/usuarios/' + this.usuario.id + '/correos');
            this.correos = correos.datos;
        },
        async guardar(id) {
            try {
                this.$store.dispatch('cambiarGuardando', true);
                const api = new PwgsApi;
                const subidadatos = { remitente_predeterminado: this.remitente_predeterminado, usuario_mail_usuario: this.usuario_mail_usuario, clave_mail_usuario: this.clave_mail_usuario, server_mail_usuario: this.server_mail_usuario, direccion_mail_usuario: this.direccion_mail_usuario, };
                await api.put('usuarios/' + id, subidadatos);
                this.$store.dispatch('cambiarGuardando', false);
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Datos guardados correctamente', life: 2000 });
            }
            catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'El trabajo no se puede modificar ya que está incluido en otro presupuesto generado', life: 5000 });
            }
        },
    },
    watch: {
        usuario() {
            this.cargardatos();
            this.cargarCorreos();
        }
    },
}
</script>